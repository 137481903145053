import React, { useEffect, useState } from 'react';

function ServiceTerms() {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        // Fetch the HTML content from the server
        fetch('/Terms_of_Service_Agreement.html')
            .then(response => response.text())
            .then(data => setHtmlContent(data))
            .catch(error => console.error('Error fetching HTML:', error));
    }, []);

    return (
        <div className='text-left p-10' dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}

export default ServiceTerms;
