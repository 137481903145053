import { useEffect } from "react";
import GradientText from "../components/GradientText.component";
import ContactForn from "../components/ContactForn.component";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaReddit, FaXTwitter, FaLinkedin } from "react-icons/fa6";
import Footer from "../components/Footer.component";
import FAQ from "../components/Faq.component";
import ScrollToSection from '../utils/ScrollToSection'

const faqData = [
    {
        question: "What is Qubitware?",
        answer:
            "Qubitware IT Solutions LLP is a global IT services and consulting company specializing in a broad spectrum of technology solutions designed to enhance business operations and drive digital transformation. The company provides end-to-end services that include cloud computing, cybersecurity, managed IT services, and advanced AI-driven solutions.",
    },
    {
        question: "What services does Qubitware offer?",
        answer:
            <>
                <strong>Qubitware offers the following services:</strong><br/>
                <ul className="list-disc pl-10">
                    <li>Microsoft 365 Deployment and Management</li>
                    <li>Azure Cloud Services</li>
                    <li>Security Solutions and Compliance</li>
                    <li>Managed IT Services</li>
                    <li>Device Management</li>
                    <li>Virtual Desktop Infrastructure (VDI) Solutions</li>
                    <li>Microsoft Teams Consulting</li>
                    <li>Data Backup and Recovery</li>
                    <li>IT Support and Helpdesk Services</li>
                    <li>Training and Workshops</li>
                    <li>Network Design and Implementation</li>
                    <li>IT Consulting and Strategy Planning</li>
                    <li>Software Licensing and Asset Management</li>
                    <li>Cybersecurity Training and Awareness</li>
                    <li>Full Stack Development</li>
                    <li>Custom Software Development</li>
                    <li>Data Science and Analytics</li>
                    <li>Chatbot Development</li>
                    <li>Business Intelligence Solutions</li>
                    <li>Robotic Process Automation (RPA)</li>
                    <li>Natural Language Processing (NLP)</li>
                    <li>Computer Vision Solutions</li>
                    <li>AI-Powered Personalization</li>
                    <li>AI Consultation and Strategy</li>
                </ul>
            </>,
    },
    {
        question: "How can I contact Qubitware?",
        answer:
            "You can reach out to us via email, phone or by filling the contact us form (helpdesk@qubitware.in phone: +91 9354407238)",
    },
    {
        question: "How can I request a quote from Qubitware?",
        answer:
            "You can request a quote from Qubitware by filling out the contact form on our website or by contacting us directly. Our team will get back to you with a customized quote based on your requirements.",
    },
    {
        question: "Does Qubitware offer custom solutions?",
        answer:
            "Yes, Qubitware offers custom solutions tailored to your business needs. Our team of experts will work with you to create a website or mobile application that meets your specific requirements and goals.",
    },
];

export default function ContactUs() {
    useEffect(() => {
        document.title = "Contact Us | QUBITWARE"
        // window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="flex flex-col gap-12
            md:py-6
        ">
            <ScrollToSection/>  
            {/* contact form */}
            <section
                id="contact-us-section"
                className="py-2">
                <div className="container mx-auto grid grid-cols-1 gap-6 max-w-6xl">
                    <div className="flex flex-col gap-2">
                        <GradientText className="text-4xl font-bold
                            2xl:text-5xl
                        ">
                            Contact Us
                        </GradientText>
                        <h1 className="text-lg text-slate-500
                            md:text-xl
                        ">
                            Any questions or remarks? Just write us a message!
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 justify-between shadow-2xl rounded-2xl bg-white p-4
                        md:p-8 md:gap-6
                        lg:grid-cols-2
                    ">
                        {/* left container */}
                        <div className="bg-cblue rounded-t-2xl text-white p-4 text-left flex flex-col justify-between gap-6
                            md:gap-8 md:p-8
                            lg:rounded-none lg:rounded-s-2xl
                        ">
                            {/* Heading */}
                            <div>
                                <h1 className="text-2xl font-bold">
                                    Contact Information
                                </h1>
                                <p className="text-base text-gray-300">
                                    Let's start with a conversation
                                </p>
                            </div>

                            {/* Contact Details */}
                            <div className="flex flex-col gap-2 text-md font-base text-gray-200">
                                {[
                                    {
                                        icon: <IoCallSharp className="size-4 self-center text-white" />,
                                        text: "+91 9354407238"
                                    },
                                    {
                                        icon: <MdEmail className="size-4 self-center text-white" />,
                                        text: (
                                            <>
                                                helpdesk@<br className="xl:hidden"/>
                                                qubitware.in
                                            </>
                                        )
                                    },
                                    {
                                        icon: <FaLocationDot className="size-4 self-center text-white" />,
                                        text: (
                                            <>
                                                Bishanawala, Jaipur
                                                <br />
                                                Panchyawala Jaipur
                                                <br />
                                                -302034
                                                Rajasthan
                                            </>
                                        )
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="flex justify-start gap-6">
                                        {item.icon}
                                        <span>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                            {/* Social Links */}
                            <div className="flex justify-start gap-6">
                                {[
                                    { icon: <FaReddit className="w-6 h-6 hover:hover_border duration-100" />, link: "https://www.reddit.com/user/qubitware/" },
                                    { icon: <FaXTwitter className="w-6 h-6 hover:hover_border duration-100" />, link: "https://x.com/technologi21594" },
                                    { icon: <FaLinkedin className="w-6 h-6 hover:hover_border duration-100" />, link: "https://www.linkedin.com/company/jmr-technologies-in/" }
                                ].map((item, index) => (
                                    <a target="_blank"
                                        rel="noopener noreferrer" 
                                        href={item.link}
                                        key={index} 
                                        className="flex items-center">
                                        {item.icon}
                                    </a>
                                ))}
                            </div>  
                        </div>

                        {/* Right container */}
                        <ContactForn
                            formClasses="py-4"
                        />
                    </div>
                </div>
            </section>
            <section id="faq-section" >
                <div className="container mx-auto my-6 max-w-5xl
                ">
                    <FAQ faqData={faqData} />
                </div>
            </section>
            <Footer varient="blue" />
        </div>
    );
}
