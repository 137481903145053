import logo_main from "../assets/logo/Main-Logo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FaReddit, FaXTwitter, FaLinkedin } from "react-icons/fa6";
import { motion } from "framer-motion";

export default function Navbar() {
    const [activeTab, setActiveTab] = useState(null);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();
    const navRef = useRef();
    let prevScrollpos = window.scrollY;

    const toggleMenu = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handlePageReload = () => {
        const currentPath = location.pathname.split("/")[1];
        for (let i = 0; i < tabData.length; i++) {
            if (tabData[i].link.toLowerCase() === "/" + currentPath) {
                setActiveTab(tabData[i].label);
                break;
            }
        }
        navRef.current.click();
    };

    const tabData = [
        { label: "Home", link: "/" },
        { label: "Services", link: "/services" },
        { label: "Partners", link: "/partners" },
        { label: "Resources", link: "/blogs" },
        { label: "Contact Us", link: "/contact-us" },
    ];

    const navScrollHandler = function () {
        const currentScrollPos = window.scrollY;

        if (prevScrollpos > currentScrollPos) {
            navRef.current.style.top = "0";
            if (currentScrollPos !== 0) {
                navRef.current.style["boxShadow"] =
                    "-4px 36px 45px -25px rgba(0,0,0,0.22)";
            } else {
                navRef.current.style["boxShadow"] = "";
            }
        } else {
            navRef.current.style.top = "-80px";
            navRef.current.style["boxShadow"] = "";
        }
        prevScrollpos = currentScrollPos;
    };

    const onClickHandler = (tab_label) => {
        setActiveTab(tab_label);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", navScrollHandler);
        handlePageReload();
        setIsNavOpen(false);
        return () => {
            window.removeEventListener("scroll", navScrollHandler);
        };
    }, [location.pathname, navRef.current]);
    return (
        <section
            ref={navRef}
            className="h-20 z-10 sticky bg-white flex items-center duration-500 ease-out
            "
        >
            <div className="container mx-auto grid grid-cols-2 
                lg:grid-cols-3 
                xl:grid-cols-5
            ">
                {/* Logo */}
                <Link className="flex items-center max-h-16 py-1 lg:col-span-1" to="/">
                    <img
                        className="h-full 
                        "
                        src={logo_main}
                        alt="Logo"
                        />
                </Link>
                {/* Hamburger Menu */}
                <div className="flex justify-end items-center z-50
                    lg:hidden
                ">
                    <button
                        className="flex flex-col justify-around size-8 cursor-pointer
                        "
                        onClick={toggleMenu}
                    >
                        <motion.span
                            initial={{ rotate: 0 }}
                            animate={isNavOpen ? { rotate: 42 } : { rotate: 0 }}
                            transition={{ duration: 0.3 }}
                            className="w-full h-[4px] bg-cblue rounded-full origin-left"
                        ></motion.span>
                        <span
                            className={`w-full h-[4px] rounded-full ${
                                isNavOpen ? "bg-transparent" : "bg-cblue"
                            }`}
                        ></span>
                        <motion.span
                            initial={{ rotate: 0 }}
                            animate={
                                isNavOpen ? { rotate: -42 } : { rotate: 0 }
                            }
                            transition={{ duration: 0.3 }}
                            className="w-full h-[4px] bg-cblue rounded-full origin-left"
                        ></motion.span>
                    </button>

                    {/* Hamburger Nav Links Links */}
                    <div
                        className={`${
                            isNavOpen ? "flex" : "hidden"
                        } absolute top-20 right-0 w-full bg-white bg-opacity-95 flex-col gap-4 z-20 text-lg rounded-b-3xl`}
                    >
                        <ul className="flex flex-col items-center gap-4">
                            {tabData.map((tab, index) => {
                                return (
                                    <li>
                                        <Link
                                            key={index}
                                            to={tab.link}
                                            className={`font-bold ${
                                                activeTab === tab.label
                                                    ? "border-b-2 border-cblue text-cblue"
                                                    : "text-gray-800"
                                            }`}
                                            onClick={() => {
                                                onClickHandler(tab.label);
                                                toggleMenu();
                                            }}
                                        >
                                            {tab.label}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="flex justify-center gap-x-2">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.reddit.com/user/qubitware/"
                            >
                                <FaReddit className="medium_icon" />
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://x.com/technologi21594"
                            >
                                <FaXTwitter className="medium_icon" />
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/company/jmr-technologies-in/"
                            >
                                <FaLinkedin className="medium_icon" />
                            </a>
                        </div>
                    </div>
                </div>
                {/* Tabs */}
                <div className="hidden gap-x-5 items-center text-lg
                    lg:flex lg:justify-end lg:col-span-2
                    xl:col-span-3
                ">
                    {tabData.map((tab, index) => {
                        return (
                            <Link
                                key={index}
                                to={tab.link}
                                className={`tab_item ${
                                    activeTab === tab.label
                                        ? "active_tab_item"
                                        : "hover:hover_tab_item"
                                }`}
                                onClick={() => onClickHandler(tab.label)}
                            >
                                {tab.label}
                            </Link>
                        );
                    })}
                </div>
                {/* Social Links */}
                <div className="hidden items-center justify-end
                    xl:flex
                ">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.reddit.com/user/qubitware/"
                    >
                        <FaReddit className="medium_icon 
                        " />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://x.com/technologi21594"
                    >
                        <FaXTwitter className="medium_icon
                        " />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/jmr-technologies-in/"
                    >
                        <FaLinkedin className="medium_icon
                        " />
                    </a>
                </div>
            </div>
        </section>
    );
}
