import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import Navbar from './components/Navbar.component';
import Blogs, { blogs_routes } from './pages/Blogs.page';
import Home, { fetchXLatestBlogs } from './pages/Home.page';
import Services from './pages/Services.page';
import Partners from './pages/customers/Customers';
import ContactUs from './pages/ContactUs.page';
import PrivacyPolicy from './pages/PrivacyPolicy.Page'
import ServiceTerms from './pages/ServiceTerms.page';
import NotFound from './pages/not_found/NotFound';
import BlogPost from './pages/BlogPost.page';
import { services_routes } from './pages/Services.page';
import { getBlogLoader } from './pages/BlogPost.page';
import { blogsPageDataLoader } from './pages/Blogs.page';

export default function App() {
  function Layout() {
    return (
      <div className="w-screen">
        <Navbar />
        <Outlet />
      </div>
    );
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
          loader: fetchXLatestBlogs,
        },
        {
          path: '/services/*',
          element: <Services />,
          children : services_routes  
        },
        {
          path: '/blogs',
          element: <Blogs />,
          children: blogs_routes
        },
        {
          path: '/partners',
          element: <Partners />,
        },
        {
          path: '/contact-us',
          element: <ContactUs />,
        },
      ],
    },
    {
      path: '/privacypolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms-of-service',
      element: <ServiceTerms />,
    },
    {
      path: '/not-found',
      element: <NotFound />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
