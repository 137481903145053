import { useLoaderData, useParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useRef, useState } from "react";
import Timestamp from "react-timestamp"
import Footer from "../components/Footer.component";
import Tag from "../components/Tag.component";

export const getBlogLoader = async ({params}) => {
    return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/${params.blog_id}/`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
        });
}

const stringToHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.body.innerHTML;
};

const BlogPost = () => {
    const { blog_id } = useParams();
    const blogContent = useLoaderData();
    const { content, 
        title, 
        banner, 
        short_description, 
        author, 
        read_time, 
        created_at, 
        tags } = blogContent;
    const blogBodyContainerRef = useRef();
    
    useEffect(() => {
        const blogData = stringToHTML(content);
        blogBodyContainerRef.current.innerHTML = blogData;
        document.title = `${title} | Blogs | QUBITWARE`
        window.scrollTo(0, 0);
    }, [content]);

    return (
        <>
            <section className="pb-12">
                <div className="container mx-auto flex flex-col text-left 
                        md:gap-8 md:mb-8
                        xl:text-xl
                        2xl:max-w-4xl
                    ">
                    {/* Banner Image */}
                    <img
                        src={banner}
                        alt="bannerImage"
                        className="w-full xl:aspect-video object-cover object-center bg-slate-100"
                    />

                    {/* All Blog Tags */}
                    <div className="flex gap-2 my-4 flex-wrap">
                        {tags &&
                            tags.map((tag, index) => (
                                <Tag key={index} name={tag} />
                            ))}
                    </div>

                    {/* Blog Meta */}
                    <div className=" flex flex-col gap-2 mb-4
                    xl:gap-4
                    ">
                        <h1 className="text-3xl font-extrabold
                            md:text-4xl
                            xl:text-6xl
                        ">{title}</h1>
                        <p className="font-semibold text-slate-400
                            md:text-lg
                            xl:text-2xl
                        ">
                            {short_description}
                        </p>
                        <hr className="m-0" />
                        <div className="font-light">
                            <span className="text-black font-semibold capitalize">
                                {author}
                            </span>{" "}
                            .{" "}
                            <span className="text-slate-400">
                                {read_time} Min Read
                            </span>{" "}
                            .{" "}
                            <Timestamp
                                className="text-slate-400"
                                relative
                                date={created_at}
                            />
                        </div>
                        <hr className="mt-0" />
                    </div>
                    <article
                        className="prose max-w-full prose-img:w-full prose-figcaption:text-center font-[montserrat]
                                sm:prose-sm
                                md:prose-base
                                lg:prose-lg
                                xl:prose-xl
                        "
                        ref={blogBodyContainerRef}
                    ></article>
                </div>

            </section>
            <Footer varient="blue" />
        </>
    );
};

export default BlogPost;
